import React from 'react'
// import { Link } from "gatsby";

import facebook from '../img/social/facebook.svg'
import instagram from '../img/social/instagram.svg'
import twitter from '../img/social/twitter.svg'

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer has-background-black has-text-white-ter">
        <div className="content has-text-centered has-background-black has-text-white-ter">
          <div className="container has-background-black has-text-white-ter">
            <div className="columns" style={{ marginRight: 0, marginLeft: 0 }}>
              <div className="column is-4">
                <section className="menu">
                  <ul className="menu-list">
                    <li>
                      <a className="navbar-item" href="https://collegepulse.com/careers">
                        Careers
                      </a>
                    </li>
                    <li>
                      <a className="navbar-item" href="https://collegepulse.com/team">
                        Team
                      </a>
                    </li>
                    <li>
                      <a className="navbar-item" href="https://collegepulse.com/pulse-media-kit">
                        Media Kit
                      </a>
                    </li>
                    <li>
                      <a
                        className="navbar-item"
                        href="https://collegepulse.com/ambassadors"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Become a Campus Ambassador
                      </a>
                    </li>
                  </ul>
                </section>
              </div>
              <div className="column is-4">
                <section>
                  <ul className="menu-list">
                    <li>
                      <a className="navbar-item" href="https://collegepulse.com/terms-of-service">
                        Terms of Service
                      </a>
                    </li>
                    <li>
                      <a className="navbar-item" href="https://collegepulse.com/contact-us">
                        Contact
                      </a>
                    </li>
                  </ul>
                </section>
              </div>
              <div className="column is-4 social">
                <a title="facebook" href="https://www.facebook.com/PulseSurvey/">
                  <img src={facebook} alt="Facebook" style={{ width: '1em', height: '1em' }} />
                </a>
                <a title="twitter" href="https://twitter.com/@CollegeInsights">
                  <img
                    className="fas fa-lg"
                    src={twitter}
                    alt="Twitter"
                    style={{ width: '1em', height: '1em' }}
                  />
                </a>
                <a title="instagram" href="https://www.instagram.com/college_pulse/?hl=en">
                  <img src={instagram} alt="Instagram" style={{ width: '1em', height: '1em' }} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
