import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { navigate } from 'gatsby'
import mixpanel from 'mixpanel-browser'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import HubspotModal from './marketplace/common/HubspotModal'
import './all.sass'
import useSiteMetadata from './SiteMetadata'
import { withPrefix } from 'gatsby'
import { isSignedIn } from '../lib/auth'
import { connect } from 'react-redux'

const TemplateWrapper = ({ children, hideHeaderFooter, requireAuth, user, ...navbarProps }) => {
  useEffect(() => {
    if (requireAuth && !isSignedIn()) {
      navigate('/login', { replace: true })
    }
  })
  const { title, description } = useSiteMetadata()
  return (
    <div>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />

        {/*<link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix("/")}img/apple-touch-icon.png`}
        />*/}
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-16x16.png`}
          sizes="16x16"
        />

        {/*<link
          rel="mask-icon"
          href={`${withPrefix("/")}img/safari-pinned-tab.svg`}
          color="#ff4400"
        />*/}
        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta property="og:image" content={`${withPrefix('/')}img/pulse-logo-whitetext.png`} />
      </Helmet>
      {!hideHeaderFooter && <Navbar {...navbarProps} />}
      <HubspotModal />
      <div>{children}</div>
      {!navbarProps.noFooter && !hideHeaderFooter && <Footer />}
    </div>
  )
}

export default connect(
  state => ({
    user: state.user.user,
  }),
  {}
)(TemplateWrapper)
