import React from 'react'
import Modal from 'react-modal'

const FORM_IDS = {
  researchConsultation: '06e77d82-379e-4be6-b131-70e32d285216',
  reportSubscription: '274b4ea6-dbb2-4d04-b7be-7b59f67d3dc9',
  solutions: '1720c742-3317-4acb-ada6-9a9ca12ccafb',
  requestQuote: '27e0535b-1e1b-45ef-ad8c-f6dd0f351c02',
  contactUs: '291fc90c-dae1-4331-8ce5-450785a54156',
}

export default class CalendarModal extends React.Component {
  componentDidMount() {
    var addScript = document.createElement('script')
    addScript.setAttribute('src', '//js.hsforms.net/forms/shell.js')
    document.body.appendChild(addScript)
  }
  componentDidUpdate() {
    if (this.props.open) {
      var createScript = document.createElement('script')
      createScript.text = `hbspt.forms.create({ portalId: "5666503", formId: "${
        FORM_IDS[this.props.hubspotForm]
      }"})`
      // const variable = '06e77d82-379e-4be6-b131-70e32d285216'
      // createScript.text = `hbspt.forms.create({ portalId: "5666503", formId: "${variable}"})`

      setTimeout(() => {
        try {
          document.getElementById('formContainer').appendChild(createScript)
        } catch (e) {
          setTimeout(() => document.getElementById('formContainer').appendChild(createScript), 200)
        }
      }, 100)
    }
  }
  /*
  <script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/shell.js"></script>
<script>
  hbspt.forms.create({
	portalId: "5666503",
	formId: "06e77d82-379e-4be6-b131-70e32d285216"
});
</script>
   */
  render() {
    const overlayBackground = 'rgba(0, 0, 0 ,0.4)'
    const modalStyles = {
      // overlay: {
      //   position: 'fixed',
      //   top: 0,
      //   left: 0,
      //   right: 0,
      //   bottom: 0,
      //   padding: '20px',
      //   backgroundColor: overlayBackground,
      //   zIndex: 1000,
      // },
      overlay: {
        backgroundColor: overlayBackground,
      },
      content: {
        top: '12%',
        bottom: '12%',
        left: '19%',
        right: '19%',
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
        borderRadius: 0,
        borderColor: '#1f2e3d',
        borderWidth: 3,
      },
      backgroundColor: overlayBackground,
    }

    return (
      <Modal
        isOpen={this.props.open}
        contentLabel="create survey modal"
        style={modalStyles}
        onRequestClose={this.props.toggleHubspotModal}
      >
        <div className="calendarCloseButton" onClick={this.props.toggleHubspotModal}>
          Close
        </div>
        <div id="formContainer" style={{ paddingBottom: 20, paddingLeft: 60, paddingRight: 60 }} />
      </Modal>
    )
  }
}
