import React from 'react'
import { Link } from 'gatsby'
import logo from '../img/pulse-logo-whitetext.png'
import { IconContext } from 'react-icons'
import { connect } from 'react-redux'
import { IoMdMenu, IoIosTrendingUp, IoMdBook, IoIosApps } from 'react-icons/io'
import { FaBullseye } from 'react-icons/fa'
import { isSignedIn, logout } from '../lib/auth'
import mixpanel from 'mixpanel-browser'

const Navbar = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      navBarActiveClass: '',
      isClient: false,
    }
  }

  componentDidMount() {
    this.setState({ isClient: true })
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState(
              {
                navBarActiveClass: 'is-active',
              },
              // After hamburger opens, tell mixpanel
              () => {
                mixpanel.track('ri:nav-hamburger', {
                  action: 'Hamburger Opened',
                })
              }
            )
          : this.setState(
              {
                navBarActiveClass: '',
              },
              // After hamburger closes, tell mixpanel
              () => {
                mixpanel.track('ri:nav-hamburger', {
                  action: 'Hamburger Closed',
                })
              }
            )
      }
    )
  }

  render() {
    const cpUser =
      this.props.user &&
      this.props.user.email &&
      (this.props.user.email.includes('@collegepulse.com') ||
        this.props.user.email.includes('demo@') ||
        this.props.user.email.includes('pulse.testuser@'))
    const { guest } = this.props.user || {}
    return (
      <nav
        className="navbar is-transparent"
        role="navigation"
        aria-label="dropdown navigation"
        key={this.state.isClient}
      >
        <div className="container">
          <div className="navbar-brand">
            {/*<Link to="/" className="navbar-item" title="Logo">
              <img src={logo} alt="Pulse" style={{ height: "100%" }} />
            </Link>*/}
            <a
              onClick={() =>
                mixpanel.track('ri:nav-brand', {
                  action: 'Clicked Logo',
                })
              }
              href="https://collegepulse.com"
              className="navbar-item"
              title="Logo"
            >
              <img src={logo} alt="Pulse" style={{ height: '100%' }} />
            </a>
            {/* Hamburger menu */}
            <div
              className={`navbar-burger has-text-white has-background-transparent is-white burger ${this.state.navBarActiveClass}`}
              data-target="navbar-dropdown"
              onClick={() => this.toggleHamburger()}
            >
              <span className="is-white" />
              <span className="is-white" />
              <span className="is-white" />
            </div>
          </div>
          {isSignedIn() && !guest && (
            <div className="links-container-navbar is-hidden-mobile" key="mainTabs">
              <Link
                onClick={() => {
                  mixpanel.track('ri:nav-main', {
                    action: 'Navigate: Brand',
                  })
                }}
                to="/marketplace/brand/tracking"
                id="brandTab"
                className={`link ${this.props.active === 'brand' ? 'active' : ''}`}
              >
                <IconContext.Provider
                  value={{
                    color:
                      this.props.active === 'brand'
                        ? 'rgba(255, 255, 255)'
                        : 'rgba(255, 255, 255, 0.75)',
                    size: '1.25em',
                    className: 'navbar-icon',
                  }}
                >
                  <IoIosTrendingUp />
                </IconContext.Provider>
                <span className="text">Brand</span>
              </Link>
              <Link
                onClick={() => {
                  mixpanel.track('ri:nav-main', {
                    action: 'Navigate: Insights',
                  })
                }}
                to="/marketplace/reports"
                id="playbooksTab"
                className={`link ${this.props.active === 'reports' ? 'active' : ''}`}
              >
                <IconContext.Provider
                  value={{
                    color:
                      this.props.active === 'reports'
                        ? 'rgba(255, 255, 255)'
                        : 'rgba(255, 255, 255, 0.75)',
                    size: '1.25em',
                    className: 'navbar-icon',
                  }}
                >
                  <IoMdBook />
                </IconContext.Provider>
                <span className="text">Insights</span>
              </Link>
              <Link
                onClick={() => {
                  mixpanel.track('ri:nav-main', {
                    action: 'Navigate: Analytics',
                  })
                }}
                to="/marketplace/tools"
                id="playbooksTab"
                className={`link ${this.props.active === 'tools' ? 'active' : ''}`}
              >
                <IconContext.Provider
                  value={{
                    color:
                      this.props.active === 'tools'
                        ? 'rgba(255, 255, 255)'
                        : 'rgba(255, 255, 255, 0.75)',
                    size: '1.25em',
                    className: 'navbar-icon',
                  }}
                >
                  <IoIosApps />
                </IconContext.Provider>
                <span className="text">Analytics</span>
              </Link>
              <Link
                onClick={() => {
                  mixpanel.track('ri:nav-main', {
                    action: 'Navigate: Messages & Experiments',
                  })
                }}
                to="/marketplace/messages"
                id="playbooksTab"
                className={`link ${this.props.active === 'messages' ? 'active' : ''} ${'disabled'}`}
                style={{
                  pointerEvents: 'none',
                }}
                disabled
              >
                <IconContext.Provider
                  value={{
                    color:
                      this.props.active === 'messages'
                        ? 'rgba(255, 255, 255)'
                        : 'rgba(255, 255, 255, 0.75)',
                    size: '1.05em',
                    className: 'navbar-icon',
                  }}
                >
                  <FaBullseye />
                </IconContext.Provider>
                <span className="text">Messages & Experiments</span>
              </Link>
              {/*<p
                id="researchTab"
                className={`link ${this.props.active === "research" ? "active" : ""} disabled`}
              >
                <IconContext.Provider
                  value={{
                    color: "rgba(255, 255, 255, 0.4)",
                    size: "1.25em",
                    className: "navbar-icon",
                  }}
                >
                  <IoIosLock />
                </IconContext.Provider>
                <span className="text">Targeting</span>
              </p>*/}
            </div>
          )}
          <div id="navMenu" className={`navbar-menu ${this.state.navBarActiveClass}`} key="navMenu">
            <div className="navbar-end has-text-centered">
              {!isSignedIn() && (
                <Link
                  className="navbar-item has-text-white is-hidden-mobile"
                  to="/login"
                  style={{ marginRight: 20 }}
                >
                  Login
                </Link>
              )}
              <div className="navbar-item has-dropdown is-hoverable">
                <div className="hover-icon is-hidden-mobile">
                  <IconContext.Provider
                    value={{
                      color: 'white',
                      size: '2em',
                      className: 'navbar-icon',
                    }}
                  >
                    <IoMdMenu />
                  </IconContext.Provider>
                </div>

                <div className="navbar-dropdown is-right">
                  <Link
                    onClick={() => {
                      mixpanel.track('ri:nav-hamburger', {
                        action: 'Navigate: Custom Research',
                        notes: 'Mobile Only Button',
                      })
                    }}
                    className="navbar-item is-hidden-tablet"
                    to="/marketplace/research"
                  >
                    Custom Research
                  </Link>
                  <Link
                    onClick={() => {
                      mixpanel.track('ri:nav-hamburger', {
                        action: 'Navigate: Report Marketplace',
                        notes: 'Mobile Only Button',
                      })
                    }}
                    className="navbar-item is-hidden-tablet"
                    to="/marketplace/insights"
                  >
                    Report Marketplace
                  </Link>
                  <Link
                    onClick={() => {
                      mixpanel.track('ri:nav-hamburger', {
                        action: 'Navigate: Solutions',
                        notes: 'Mobile Only Button',
                      })
                    }}
                    className="navbar-item is-hidden-tablet"
                    to="/marketplace/solutions"
                  >
                    Solutions
                  </Link>
                  {/*
                  <Link className="navbar-item" to="/about">
                    About
                  </Link>
                  <Link className="navbar-item" to="/products">
                    Work With Us
                  </Link>
                  <Link className="navbar-item" to="/blog">
                    The Pulse
                  </Link>
                  <Link className="navbar-item" to="/blog">
                    Press Room
                  </Link>
                  */}
                  {/*<Link
                    className="navbar-item"
                    to="/about"
                  >
                    About
                  </Link>*/}
                  <a
                    onClick={() => {
                      mixpanel.track('ri:nav-hamburger', {
                        action: 'Navigate: About',
                      })
                    }}
                    className="navbar-item"
                    href="https://collegepulse.com/about-us"
                  >
                    About
                  </a>
                  <a
                    onClick={() => {
                      mixpanel.track('ri:nav-hamburger', {
                        action: 'Navigate: Work With Us',
                      })
                    }}
                    className="navbar-item"
                    href="https://collegepulse.com/partner-with-us"
                  >
                    Work With Us
                  </a>
                  <a
                    onClick={() => {
                      mixpanel.track('ri:nav-hamburger', {
                        action: 'Navigate: The Pulse',
                      })
                    }}
                    className="navbar-item"
                    href="https://collegepulse.com/the-pulse"
                  >
                    The Pulse
                  </a>
                  <a
                    onClick={() => {
                      mixpanel.track('ri:nav-hamburger', {
                        action: 'Navigate: Press Room',
                      })
                    }}
                    className="navbar-item"
                    href="https://collegepulse.com/press"
                  >
                    Press Room
                  </a>
                  {/*<Link className="navbar-item" to="/methodology">
                    Methodology
                  </Link>*/}
                  <a
                    onClick={() => {
                      mixpanel.track('ri:nav-hamburger', {
                        action: 'Navigate: Methodology',
                      })
                    }}
                    className="navbar-item"
                    href="https://collegepulse.com/methodology"
                  >
                    Methodology
                  </a>
                  <a
                    onClick={() => {
                      mixpanel.track('ri:nav-hamburger', {
                        action: 'Navigate: Careers',
                      })
                    }}
                    className="navbar-item"
                    href="https://collegepulse.com/careers"
                  >
                    Careers
                  </a>
                  {isSignedIn() && (
                    <span
                      onClick={() => {
                        mixpanel.track('ri:nav-hamburger', {
                          action: 'Action: Sign Out',
                        })
                      }}
                      className="navbar-item cursor-hover"
                      onClick={logout}
                    >
                      Logout
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    )
  }
}

// export default Navbar;

export default connect(
  state => ({
    user: state.user.user,
  }),
  {}
)(Navbar)
