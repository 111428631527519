import React from 'react'
import { connect } from 'react-redux'
import { toggleHubspotModal } from '../../../redux/actions/modals'
import StandaloneHubspotModal from './StandaloneHubspotModal'

class CalendarModal extends React.Component {
  render() {
    return <StandaloneHubspotModal {...this.props} />
  }
}

export default connect(
  state => ({
    open: state.modals.hubspotModal.isOpen,
    hubspotForm: state.modals.hubspotModal.hubspotForm,
  }),
  { toggleHubspotModal }
)(CalendarModal)
